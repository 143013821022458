import React from 'react'
import Layout from "../components/layout";
import moment from "moment";
import "./oneFAQTemplate.css";
import { Link } from "gatsby"
import { ATInternetTagService } from './../services/global/ATInternetService';
import { getWeekYearWithOptions } from 'date-fns/fp';

class OneFAQTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            youtubeDone: false,
        }
    }

    url;
    cat;
    componentWillMount() {
        this.url = this.props.location.href
        switch ( this.props.pageContext.faq.category) {
            case "QUESTIONS_FREQUENTES":
                this.cat = 'Questions fréquentes'
                break
            case "ADHESION":
                this.cat = 'Adhésion'
                break
            case "FONCTIONNEMENT":
                this.cat = 'Fonctionnement'
                break
            case "REMBOURSEMENT":
                this.cat = 'Remboursement'
                break
            case "SERVICES":
                this.cat = 'Services'
                break
            case "VIE_DU_CONTRAT":
                this.cat = 'Vie du contrat'
                break
            case "REGLEMENTATION":
                this.cat = 'Règlementation'
                break
            default:
                this.cat = 'Questions fréquentes'
                break
        }
    }
    componentDidMount = () => {
        ATInternetTagService.sendUrledPage(this.props.uri,{page_chapter1:'FAQ'});
        if (!ATInternetTagService.isThereYoutubeVideo(this.props.pageContext.faq.content)) {
            return;
        }
        // Youtube Stuff
        // On mount, check to see if the API script is already loaded
        if (typeof window === "undefined") {
            return;
        }
        if (!window.YT) { // If not, load the script asynchronously
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';
    
          // onYouTubeIframeAPIReady will load the video after the script is loaded
          window.onYouTubeIframeAPIReady = this.loadVideo;
    
          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
        } else { // If script is already there, load the video directly
          this.loadVideo();
        }
      };

        // Youtube Stuff
    loadVideo = () => {
        this.player = new window.YT.Player(`player`, {
          events: {
            onStateChange: this.onPlayerStateChange
          }
        });
      }

        // Youtube Stuff
    onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.PLAYING && !this.state.youtubeDone) {
            ATInternetTagService.sendYoutubeClick(this.props.pageContext.faq)
            this.setState({youtubeDone:true});
        }
      }

    render() {
        const {faq} = this.props.pageContext;
        const keywords = faq.keywords != null ? faq.keywords.split() : [];
        const tracker = faq.tracker;
        const metaDescription = faq.metaDescription;
        return (
            <Layout titleSeo={faq.titleSEO} keywordsSeo={keywords} description={metaDescription} tracker={tracker} faq={true}>
                <section className="pageHeader">
                    <div className="pageHeaderContainer">
                        <nav>
                            <ul className="breadcrumb">
                                <li className="breadcrumbItem"><a href="/" className="breadcrumbItemLink breadcrumbItemLinkHome"><i className="icon-mgc"/></a></li>
                                <li className="breadcrumbItem"><a href="/faq/" className="breadcrumbItemLink breadcrumbItemLinkMiddle">FAQ</a></li>
                                <li className="breadcrumbItem"><span className="breadcrumbItemLink">{this.cat}</span></li>
                            </ul>
                        </nav>
                        <div className="ciblePubDesktop">
                        </div>
                    </div>
                </section>
                <section className="pageHeader callback">
                    <div>
                        <p className="pageHeaderTitle faqTitle">Foire aux questions</p>
                    </div>
                </section>
                <div className="container">
                    <section className="faqArticle">

                        <div className="faqArticleHeader">
                            <div>
                                <h1 className="faqArticleHeaderTitle">{faq.title}</h1>
                                <p className="faqArticleHeaderDate">Publié le {moment(faq.publishedAt).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                        <div className="faqArticleBody articleBodyTable" dangerouslySetInnerHTML={{ __html: faq.content }} />
                        <div>
                            {this.props.location.state ? (
                            <Link
                                to="/faq/"
                                className="return"
                                state={this.props.location.state}
                            >
                                <i className="fas fa-chevron-left" />
                                Consulter toutes les questions de la FAQ
                            </Link>
                        ) : (
                            <a href="/faq/" className="return">
                                <i className="fas fa-chevron-left" />
                                Consulter toutes les questions de la FAQ
                            </a>
                        )}
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}
export default OneFAQTemplate
